import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate } from 'gatsby';

import { Layout, LinkWrapper, SEO, TextBlock } from '@components';
import { Box, Button } from '@core';

import image from '@images/404.svg';

import { PAGE_DATA } from '@shared/constants/pages/page-404';
import { query } from '@shared/style-helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';
import { replaceLanguageFromSlug } from '@shared/constants/languages';
import { isClient } from '@shared/helpers';

const { metaData, header, button } = PAGE_DATA;

const Container = styled.div`
  // 104px = navbar + ip-bar
  height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  height: 60%;
  margin-bottom: 32px;

  @media ${query.tablet} {
    margin-top: 56px;
    margin-bottom: 64px;
  }

  @media ${query.maxMobile} {
    height: calc(100% - 350px);
    margin-bottom: 16px;
  }
`;

const Image = styled.div`
  height: 100%;
  background-image: ${`url(${image})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
`;

const NotFoundPageTemplate = ({ location }) => {
  const { t } = useTranslation();

  useEffect(() => {
    trackCustomGA4Event({
      eventName: GA4_EVENTS.pageNotExists,
      params: {
        url: location.href,
      },
    });
  }, [location.href]);

  return (
    <Layout location={location} footerProps={{ isHidden: true }}>
      <SEO {...metaData(t)} hideUrl />
      <Container>
        <ImageContainer>
          <Image />
        </ImageContainer>
        <Box mx={{ _: 24, md: 40 }}>
          <TextBlock {...header(t)} textAlign="center" />
        </Box>
        <Box mt={32} mb={40} display="flex" justifyContent="center">
          <LinkWrapper link={button(t).link}>
            <Button type="button" size="large" width={280}>
              {button(t).title}
            </Button>
          </LinkWrapper>
        </Box>
      </Container>
    </Layout>
  );
};

const splitPathname = (pathname) => {
  const pathWithoutLanguage = replaceLanguageFromSlug(pathname);
  return {
    hasLanguage: pathname !== pathWithoutLanguage,
    pathWithoutLanguage,
  };
};

const removeTrailingSlash = (text) => text.replace(/\/+$/, '');

const NotFoundPage = ({ location }) => {
  const { hasLanguage, pathWithoutLanguage } = splitPathname(
    removeTrailingSlash(location.pathname)
  );

  if (location.pathname === '/auth' && location.search) {
    // Redirect to user zone if user comes with auth token to /auth page with token
    // @TODO remove this when back-end solution is implemented to fix this problem
    navigate(`${process.env.ACCOUNT_APP_URL}auth${location.search}`);
    return null;
  }

  if (hasLanguage) {
    // Redirect to english page if it is not translated to the given language.
    navigate(pathWithoutLanguage);
    return null;
  }

  return isClient && <NotFoundPageTemplate location={location} />;
};

export default NotFoundPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "navigation", "page404"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
