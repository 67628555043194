export const PAGE_DATA = {
  metaData: (t) => ({ title: t('page404:metaData.title') }),
  header: (t) => ({
    title: t('page404:header.title'),
    description: t('page404:header.description'),
  }),
  button: (t) => ({
    link: '/',
    title: t('common:actions.backToHome'),
  }),
};
